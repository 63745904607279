/*
 * filename: recaptcha.js
 *
 * contains backend recaptcha validation logic
 */

import { load } from 'recaptcha-v3';


const data = document.currentScript.dataset;
const recaptchaAction = data.action;
const siteKey = data.sitekey;


document.addEventListener('DOMContentLoaded', () => {
  document.forms.item(0).addEventListener('submit', (event) => {
    event.preventDefault();

    load(siteKey).then( (recaptcha) => {
      recaptcha.execute( {action: recaptchaAction} ).then( (token) => {
        document.forms.item(0).submit();
      });
    });
  });
});
